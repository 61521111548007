export enum AssetType {
  KiCall = 'KiCall',
  Tamo = 'Tamo',
  TamoPro = 'TamoPro',
  TamoMini = 'TamoMini',
  TamoGo = 'TamoGo',
  C500 = 'C500',
  P500 = 'P500',
  Wolflok = 'Wolflok',
  WolflokPro = 'WolflokPro',
  WolflokMini = 'WolflokMini',
  WolflokGo = 'WolflokGo',
}

export enum FriendlyAssetType {
  KiCall = 'KiCall',
  Tamo = 'Tamo',
  TamoPro = 'Tamo Pro',
  TamoMini = 'Tamo Mini',
  TamoGo = 'Tamo Go',
  C500 = 'C500',
  P500 = 'P500',
  Wolflok = 'Wolflok',
  WolflokPro = 'Wolflok Pro',
  WolflokMini = 'Wolflok Mini',
  WolflokGo = 'Wolflok Go',
  Unknown = 'Unknown'
}

export function getFriendlyAssetType(product: AssetType) {
  const friendNames = {
    [AssetType.KiCall]: FriendlyAssetType.KiCall,
    [AssetType.Tamo]: FriendlyAssetType.Tamo,
    [AssetType.TamoPro]: FriendlyAssetType.TamoPro,
    [AssetType.TamoMini]: FriendlyAssetType.TamoMini,
    [AssetType.TamoGo]: FriendlyAssetType.TamoGo,
    [AssetType.C500]: FriendlyAssetType.C500,
    [AssetType.P500]: FriendlyAssetType.P500,
    [AssetType.Wolflok]: FriendlyAssetType.Wolflok,
    [AssetType.WolflokPro]: FriendlyAssetType.WolflokPro,
    [AssetType.WolflokMini]: FriendlyAssetType.WolflokMini,
    [AssetType.WolflokGo]: FriendlyAssetType.WolflokGo,
  }

  return friendNames[product] || FriendlyAssetType.Unknown
}